import React, {useRef, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import {useProfile} from "../../../../../hooks/useProfile";
import {Icon} from "../../../../../components/Icon";

export const HabitAddModal = ({open, successfulResponse, handleClose}) => {
  const {profile, auth, initData} = useProfile();

  const titleRef = useRef(null);

  const [reminderEnabled, setReminderEnabled] = useState(false);
  const [reminderTime, setReminderTime] = useState('');

  const [interval, setInterval] = useState(21); // по умолчанию самый маленький промежуток

  const handleSubmit = () => {
    const habitData = JSON.stringify({
      title: titleRef.current?.value,
      count_days: interval,
      is_remind: reminderEnabled,
      time: reminderEnabled ? reminderTime : null,
    });

    axios
      .post("/api/v1/habit/add", habitData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "X-Telegram-Auth-Date": auth.auth_date,
          "X-Telegram-Hash": auth.hash,
          "X-Telegram-Init-Data": btoa(initData),
          User: profile.uuid,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        successfulResponse(response.data.data);
        handleClose();
      });
  };


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{
        borderRadius: 21,
      }}
    >
      <DialogTitle
        style={{
          fontSize: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 16px 0",
        }}
      >
        <div
          style={{
            height: 24,
            color: "#1982F4",
          }}
        >
          {profile.language_code === "ru" ? `Добавить привычку` : `Add habit`}
        </div>
        <div
          style={{
            height: 24,
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <Icon name={"icon-close-circle"} size={24} color={"#FF7244"}/>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          width: 320,
          padding: "0 16px 16px",
        }}
      >
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          <TextField
            inputRef={titleRef}
            label={profile.language_code === "ru" ? "Привычка" : "Habit"}
            variant="outlined"
            placeholder={
              profile.language_code === "ru" ? "Ходить в..." : "Go to the..."
            }
            style={{
              width: "calc(100% - 10px)",
            }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              onClick={() => setInterval(21)}
              variant={interval === 21 ? 'contained' : 'outlined'}
              color="secondary"
              style={{
                borderRadius: 20,
                fontSize: 12,
              }}
            >
              {profile.language_code === "ru" ? "21 день" : "21 day"}
            </Button>
            <Button
              onClick={() => setInterval(30)}
              variant={interval === 30 ? 'contained' : 'outlined'}
              color="secondary"
              style={{
                borderRadius: 20,
                fontSize: 12,
              }}
            >
              {profile.language_code === "ru" ? "30 дней" : "30 days"}
            </Button>
            <Button
              onClick={() => setInterval(60)}
              variant={interval === 60 ? 'contained' : 'outlined'}
              color="secondary"
              style={{
                borderRadius: 20,
                fontSize: 12,
              }}
            >
              {profile.language_code === "ru" ? "60 дней" : "60 days"}
            </Button>
          </div>

          <FormControlLabel
            control={
              <Switch
                checked={reminderEnabled}
                color={"success"}
                onChange={(e) => setReminderEnabled(e.target.checked)}
              />
            }
            label={
              profile.language_code === "ru"
                ? "Нужно напоминание"
                : "Need a reminder"
            }
            style={{
              width: "calc(100% - 10px)",
            }}
          />
          {reminderEnabled && (
            <Box display="flex" alignItems="center" gap={1} style={{
              justifyContent: "space-between",
              width: "calc(100% - 10px)"
            }}>
              <TextField
                value={reminderTime}
                onChange={(e) => setReminderTime(e.target.value)}
                label={profile.language_code === 'ru' ? "Время" : "Time"}
                type="time"
                placeholder={"--:--"}
                style={{
                  width: 120
                }}
                InputLabelProps={{shrink: true}}
              />
              {reminderTime !== "" && (
                <Button onClick={() => setReminderTime('')} variant="outlined" color="secondary" style={{
                  borderRadius: 24,
                  fontSize: 12
                }}>
                  {profile.language_code === 'ru' ? "Удалить время" : "No time limit"}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          padding: "0 16px 16px",
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          style={{
            width: "100%",
            color: "white",
            fontSize: 14,
            textTransform: "none",
          }}
        >
          {profile.language_code === "ru"
            ? "Создать привычку"
            : "Create a habit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
