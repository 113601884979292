import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import moment from "moment";
import {
  Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from "@mui/material";
import {Link} from "react-router";
import {useProfile} from "../../hooks/useProfile";
import {Icon} from "../../components/Icon";
import style from "../CalendarTask/CalendarTask.module.scss"; // используем стиль из другой директории, возможнло нужно его вынести в общие стили. или как то по другому оформить
import {CustomCalendar} from "../CalendarTask/CalendarTask";
import {ROOT_URL} from "../../config/url";
import "./WaterList.scss";

export const CalendarWater = () => {
  const {profile, auth, initData} = useProfile();
  const [waterMarks, setWaterMarks] = useState([]);
  const [waterMarksCount, setWaterMarksCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);

  const [selectedDate, setSelectedDate] = useState(moment());
  const currentDateRef = useRef(moment());

  const waterMax = 10

  function constructWaterMarks(markedWaterCount) {
    let waterMarks = []

    for (let i = 1; i <= waterMax; i++) {
      waterMarks.push({
        isFull: i <= markedWaterCount,
        isFirstEmpty: i === markedWaterCount + 1,
      })
    }

    return waterMarks;
  }

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  useEffect(() => {
    axios.get('/api/v1/water-marks/day', {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth.auth_date,
        'X-Telegram-Hash': auth.hash,
        'X-Telegram-Init-Data': btoa(initData),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      }, params: {
        date: selectedDate.format("YYYY-MM-DD")
      }
    }).then((response) => {
      const marksCount = response.data.data.length > 0 ? response.data.data[0]?.count_marks : 0

      // TODO: не пересобирать массив, сделать список стаканов постоянным и менять только свойства стаканов
      setWaterMarks(constructWaterMarks(marksCount))
      setWaterMarksCount(marksCount)
    });
  }, [isUpdate, selectedDate]);

  const handleMarkWater = (isChecked) => {
    let promise;

    if (isChecked) {
      promise = axios.put('/api/v1/water-mark/uncheck', {
        date: selectedDate.format("YYYY-MM-DD")
      }, {
        withCredentials: false, baseURL: ROOT_URL, headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
      })
    } else {
      promise = axios.post('/api/v1/water-mark/check', {
        date: selectedDate.format("YYYY-MM-DD")
      }, {
        withCredentials: false, baseURL: ROOT_URL, headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
      })
    }

    promise.then(() => {
      setIsUpdate(!isUpdate);
    });
  };

  return <>
    <div className={style.calendarContainer}>
      <div className={style.calendarMenu}>
        <div className={style.calendarMenu__cursor}>
          <Link to={"/dashboard"}>
            <Icon
              name={"icon-round-arrow-left"}
              size={24}
              color={"#A4A4A4"}
            />
          </Link>
        </div>
        <span className={style.calendarMenu__text}>
            {profile.language_code === "ru" ? "Вода" : "Water"}
          </span>
      </div>
      <CustomCalendar
        selectedDate={selectedDate}
        languageCode={profile.language_code}
        taskCounts={0}
        onDateChange={handleDateChange}
      />
      <div
        style={{
          border: currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "2px solid #FF713C" : "2px solid #c4c4c4",
          color: currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "#FF713C" : "#202020",
        }}
        className={style.calendarDateCurrent}
      >
        <div
          style={{
            display: "flex", justifyContent: "center",
          }}
        >
            <span style={{marginRight: 4}}>
              {profile.language_code === "ru" ? currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "Сегодня, " : "" : currentDateRef.current.format("DD.MM.YYYY") === selectedDate.format("DD.MM.YYYY") ? "Today is " : ""}
            </span>
          {selectedDate.format("DD.MM.YYYY")}
        </div>
      </div>

      <div className={'water-glasses'}>
        {waterMarks.map((mark, index) => (<div key={index} className={'water-glass'} onClick={() => handleMarkWater(mark.isFull)}>
          {mark.isFull && <Icon name={"icon-water-glass-full"} height={40} color={"#A4A4A4"}/>}

          {!mark.isFull && <>
            {mark.isFirstEmpty && <p className={'water-glass__text'}>+252<br/>{profile.language_code === 'ru' ? 'мл' : 'ml'}</p>}
            <Icon name={"icon-water-glass-empty"} height={40} color={"#A4A4A4"}/>
          </>}
        </div>))}
      </div>

      <p style={{
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#676D74',
        marginBottom: '10px',
      }}
      >
        {profile.language_code === 'ru' ? <span>Выпито {waterMarksCount} из 10</span> : <span>Drunk {waterMarksCount} of 10</span>}<br/>
      </p>

      <p style={{
        textAlign: 'center',
        color: '#676D74',
        margin: 0,
      }}
      >
        {profile.language_code === 'ru' ? <span>{250 * waterMarksCount} мл из 2500 мл</span> : <span>{250 * waterMarksCount} ml of 2500</span>}
      </p>

      <p style={{
        textAlign: 'center',
        fontSize: '10px',
        color: '#676D74',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
      }}
      >
        {profile.language_code === 'ru'
          ? 'Рекомендуется пить 2-2,5 литра воды в день, но Worky не обязывает вас следовать этим указаниям. Вы самостоятельно решаете, сколько воды вам требуется ежедневно.'
          : 'Suggest drinking 2-2.5 liters of water per day, but Worky does not oblige you to these requirements. You decide how much water you need daily.'
        }
      </p>
    </div>
  </>
}
