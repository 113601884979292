import React, {useEffect, useState} from "react";
import {Link} from "react-router";
import {Icon} from "../../../../components/Icon";
import {useProfile} from "../../../../hooks/useProfile";
import "./WaterList.scss";

export const WaterListView = (props) => {
  const {
    profile, waterMarks, handleMarkWater
  } = props;

  return (<>
      <div
        style={{
          padding: 16,
          backgroundColor: "white",
          boxShadow: "0 5px 24px 16px rgba(0, 0, 0, 0.05)",
          borderRadius: 11,
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "stretch",
        }}
      >
        <div
          style={{
            display: "flex", justifyContent: "space-between", height: 24,
          }}
        >
          <span
            style={{
              color: "#1982F4", fontSize: 18, fontWeight: "bold",
            }}
          >
            {profile.language_code === "ru" ? "Выпито за день" : "Water drunk per day"}
          </span>
          <div
            style={{
              display: "flex", justifyContent: "end",
            }}
          >
            <div
              style={{
                cursor: "pointer", height: 24,
              }}
            >
              <Link to={"/calendar-water"} aria-disabled={true} style={{textDecoration: "none"}}>
                <Icon name={"icon-calendar"} size={24}/>
              </Link>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: "8px 0", backgroundColor: "#c4c4c4", height: 1,
          }}
        />

        <div className={'water-glasses'}>
          {waterMarks.map((mark, index) => (<div key={index} className={'water-glass'} onClick={() => handleMarkWater(mark.isFull)}>
              {mark.isFull && <Icon name={"icon-water-glass-full"} height={40} color={"#A4A4A4"}/>}

              {!mark.isFull && <>
                {mark.isFirstEmpty && <p className={'water-glass__text'}>+252<br/>{profile.language_code === 'ru' ? 'мл' : 'ml'}</p>}
                <Icon name={"icon-water-glass-empty"} height={40} color={"#A4A4A4"}/>
              </>}
            </div>))}
        </div>
      </div>
    </>);
}
