
// todo сделать по нормальному, чтоб деполить можно было в разные среды https://app.weeek.net/ws/722110/task/98
function rootUrl() {
    const host = document.location.host;
    console.log(host)

    if (host === "localhost:7891") {
        return "http://localhost:7890";
    } else if (host === "test1.workyhelper.org") {
        return "https://test1.workyhelper.org";
    } else {
        return "https://app.workyhelper.org";
    }
}

export const ROOT_URL = rootUrl();