import React from "react";
import {Navigate, Route, Routes} from "react-router";
import {Dashboard} from "./Dashborad/Dashboard";
import {CalendarTask} from "./CalendarTask/CalendarTask";
import {ProfileForm} from "./ProfileForm/ProfileForm";
import Header from "../components/Header/Header";
import {ButtonOnboarding} from "./ButtonOnboarding/ButtonOnboarding";
import {FirstPage} from "./Onboarding/page1";
import {SecondPage} from "./Onboarding/page2";
import {WorkyHelp} from "./WorkyHelp/WorkyHelp";
import {EarnMoney} from "./EarnMoney/EarnMoney";
import { CalendarHabits } from "./CalendarHabits/CalendarHabits";
import {CalendarWater} from "./CalendarWater/CalendarWater";

export const Routing = () => {
  return (
    <div
      style={{
        padding: "96px 16px 16px",
        background: "linear-gradient(to bottom, #dff5f9, #d5e4fd)",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        overflowY: "auto",
      }}
    >
      <Header/>

      <Routes>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/calendar-task" element={<CalendarTask/>}/>
        <Route path="/calendar-habits" element={<CalendarHabits/>}/>
        <Route path="/calendar-water" element={<CalendarWater/>}/>
        <Route path="/profile-form" element={<ProfileForm/>}/>
        <Route path="/onboarding" element={<ButtonOnboarding/>}/>
        <Route path="/page1" element={<FirstPage/>}/>
        <Route path="/page2" element={<SecondPage/>}/>
        <Route path="/help" element={<WorkyHelp/>}/>
        <Route path="/referal" element={<EarnMoney/>}/>
        <Route path="/" element={<Navigate to="/dashboard"/>}/>
      </Routes>
    </div>
  );
};
