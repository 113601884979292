import {useProfile} from "../../../../hooks/useProfile";
import {useEffect, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";
import moment from "moment/moment";

export const useWaterList = () => {
  const {profile, auth, initData} = useProfile();
  const [isUpdate, setIsUpdate] = useState(false);
  const [waterMarks, setWaterMarks] = useState([]);

  const waterMax = 10

  function constructWaterMarks(markedWaterCount) {
    let waterMarks = []

    for (let i = 1; i <= waterMax; i++) {
      waterMarks.push({
        isFull: i <= markedWaterCount,
        isFirstEmpty: i === markedWaterCount + 1,
      })
    }

    return waterMarks;
  }

  const handleMarkWater = (isChecked) => {
    let promise;

    if (isChecked) {
      promise = axios.put('/api/v1/water-mark/uncheck', {
        date: moment().format("YYYY-MM-DD")
      }, {
        withCredentials: false, baseURL: ROOT_URL, headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
      })
    } else {
      promise = axios.post('/api/v1/water-mark/check', {
        date: moment().format("YYYY-MM-DD")
      }, {
        withCredentials: false, baseURL: ROOT_URL, headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
      })
    }

    promise.then(() => {
      setIsUpdate(!isUpdate);
    });
  };

  useEffect(() => {
    axios.get('/api/v1/water-marks/day', {
      withCredentials: false, baseURL: ROOT_URL, headers: {
        'X-Telegram-Auth-Date': auth.auth_date,
        'X-Telegram-Hash': auth.hash,
        'X-Telegram-Init-Data': btoa(initData),
        'User': profile.uuid,
        'Content-Type': 'application/json'
      }, params: {
        date: moment().format("YYYY-MM-DD")
      }
    }).then((response) => {
      const marksCount = response.data.data.length > 0 ? response.data.data[0]?.count_marks : 0

      // TODO: не пересобирать массив, сделать список стаканов постоянным и менять только свойства стаканов
      setWaterMarks(constructWaterMarks(marksCount))
    });
  }, [isUpdate]);

  return {
    profile,
    waterMarks,
    handleMarkWater: (isChecked) => handleMarkWater(isChecked),
  }
}
